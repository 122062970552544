<template>
  <v-dialog
    v-if="getCreateUserSubscriptionTransactionDialog"
    v-model="getCreateUserSubscriptionTransactionDialog"
    persistent
    scrollable
    width="900px"
  >
    <v-form ref="form" @submit.prevent="handleSave">
      <v-card
        v-if="
          userSubscription &&
          userSubscription.user &&
          userSubscription.subscription
        "
        class="popup-card"
      >
        <v-toolbar flat max-height="56px" fixed>
          <v-btn
            fab
            x-small
            :disabled="isLoading || isMakingPayment"
            @click="closeModal"
          >
            <v-icon> $close </v-icon>
          </v-btn>
          <v-card-title>
            <div>
              {{ $trans("create_payment_for_user_subscription") }}
              {{ $trans("id") }}:
              {{
                getCreateUserSubscriptionTransactionDialog.userSubscription.id
              }}
            </div>
          </v-card-title>
          <v-spacer />
          <v-btn
            color="secondary"
            depressed
            outlined
            :loading="isMakingPayment"
            :disabled="isLoading || isMakingPayment"
            @click="handleSave"
          >
            <v-icon left>$cash</v-icon>
            <span
              >{{ $trans("create_payment") }} ({{ paymentSum | money }})</span
            >
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <avatar-user-names :user="userSubscription.user" />
              </v-col>
            </v-row>
            <v-row v-if="!loadingSubscriptionPrice">
              <v-col
                v-if="
                  !showFakturowniaWarning && !showCustomerDataInvoiceWarning
                "
                cols="12"
              >
                <calendesk-information-message>
                  {{ $trans("create_payment_invoice_info") }}
                </calendesk-information-message>
              </v-col>
              <v-col
                v-else-if="showFakturowniaWarning"
                class="pt-0 mt-0"
                cols="12"
              >
                <calendesk-information-message
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                >
                  {{ $trans("invoice_warning_fakturownia_disabled") }}
                </calendesk-information-message>
              </v-col>
              <v-col
                v-else-if="showCustomerDataInvoiceWarning"
                class="pt-0 mt-0"
                cols="12"
              >
                <calendesk-information-message
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                >
                  {{ $trans("invoice_warning_client_data") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="paymentMethod"
                  :items="paymentMethods"
                  hide-details
                  :label="$trans('payment_method')"
                  outlined
                  :rules="[rules.required]"
                  :disabled="isLoading || isMakingPayment"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="amount"
                  :loading="loadingSubscriptionPrice"
                  hide-details
                  type="number"
                  :rules="[
                    rules.required,
                    rules.maxNumber,
                    rules.minNumber(amount, 0),
                  ]"
                  :label="$trans('invoice_total')"
                  outlined
                  :suffix="$config('currency')"
                  :disabled="isLoading || isMakingPayment"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="transactionId"
                  hide-details
                  :rules="[rules.maxChars(255)]"
                  :label="$trans('payment_transaction_transaction_id')"
                  outlined
                  :disabled="isLoading || isMakingPayment"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  maxChars,
  maxNumber,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  canIssueInvoiceForUser,
  paymentMethodsForSelect,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import AvatarUserNames from "@/components/AvatarUserNames";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "CreateUserSubscriptionPaymentTransactionDialog",
  components: { CalendeskInformationMessage, AvatarUserNames },
  mixins: [sharedActions, planActions],
  data() {
    return {
      isLoading: false,
      isMakingPayment: false,
      userSubscription: null,
      transactionId: null,
      loadingSubscriptionPrice: false,
      amount: 0,
      wantsInvoice: false,
      paymentMethod: this.$helpers.paymentMethods.CASH,
      paymentMethods: paymentMethodsForSelect(),
      rules: {
        required,
        maxChars,
        minNumber,
        maxNumber,
      },
    };
  },
  computed: {
    ...mapGetters({
      getCreateUserSubscriptionTransactionDialog:
        "subscriptions/getCreateUserSubscriptionTransactionDialog",
    }),
    paymentSum() {
      return this.amount * 100;
    },
    showCustomerDataInvoiceWarning() {
      return (
        this.wantsInvoice &&
        (!this.userSubscription.user ||
          !canIssueInvoiceForUser(this.userSubscription.user))
      );
    },
    showFakturowniaWarning() {
      return this.wantsInvoice && !this.isFakturowniaEnabled;
    },
  },
  watch: {
    getCreateUserSubscriptionTransactionDialog(val) {
      if (val) {
        this.userSubscription = val.userSubscription;
        this.loadSubscriptionPrice();
      }
    },
  },
  methods: {
    ...mapActions({
      create: "subscriptions/createUserSubscriptionPaymentTransaction",
      setCreateUserSubscriptionTransactionDialog:
        "subscriptions/setCreateUserSubscriptionTransactionDialog",
      fetchSubscription: "subscriptions/fetchSubscription",
    }),
    loadSubscriptionPrice() {
      this.loadingSubscriptionPrice = true;
      this.fetchSubscription(this.userSubscription.subscription.id)
        .then((subscription) => {
          if (subscription.price) {
            this.amount = subscription.price.unit_amount / 100;
            this.wantsInvoice = subscription.wants_invoice;
          }
        })
        .finally(() => {
          this.loadingSubscriptionPrice = false;
        });
    },
    closeModal() {
      this.setCreateUserSubscriptionTransactionDialog(null);
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    async add() {
      this.isMakingPayment = true;
      try {
        pushEvent("createUserSubscriptionPaymentTransaction");

        await this.create({
          user_subscription_id: this.userSubscription.id,
          amount: Math.round(this.amount * 100),
          transaction_id: this.transactionId,
          payment_method: this.paymentMethod,
        });

        this.setCreateUserSubscriptionTransactionDialog(null);
        this.$emit("close");
      } catch (error) {
        if (this.$helpers.isResponseErrorCode(error, "USER_IS_REQUIRED")) {
          errorNotification("payment_user_is_required", error, false);
        } else {
          errorNotification(null, error);
        }
      } finally {
        this.isMakingPayment = false;
      }
    },
  },
};
</script>
