var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getCreateUserSubscriptionTransactionDialog)?_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"900px"},model:{value:(_vm.getCreateUserSubscriptionTransactionDialog),callback:function ($$v) {_vm.getCreateUserSubscriptionTransactionDialog=$$v},expression:"getCreateUserSubscriptionTransactionDialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSave.apply(null, arguments)}}},[(
        _vm.userSubscription &&
        _vm.userSubscription.user &&
        _vm.userSubscription.subscription
      )?_c('v-card',{staticClass:"popup-card"},[_c('v-toolbar',{attrs:{"flat":"","max-height":"56px","fixed":""}},[_c('v-btn',{attrs:{"fab":"","x-small":"","disabled":_vm.isLoading || _vm.isMakingPayment},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v(" $close ")])],1),_c('v-card-title',[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("create_payment_for_user_subscription"))+" "+_vm._s(_vm.$trans("id"))+": "+_vm._s(_vm.getCreateUserSubscriptionTransactionDialog.userSubscription.id)+" ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":"","outlined":"","loading":_vm.isMakingPayment,"disabled":_vm.isLoading || _vm.isMakingPayment},on:{"click":_vm.handleSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$cash")]),_c('span',[_vm._v(_vm._s(_vm.$trans("create_payment"))+" ("+_vm._s(_vm._f("money")(_vm.paymentSum))+")")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('avatar-user-names',{attrs:{"user":_vm.userSubscription.user}})],1)],1),(!_vm.loadingSubscriptionPrice)?_c('v-row',[(
                !_vm.showFakturowniaWarning && !_vm.showCustomerDataInvoiceWarning
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('calendesk-information-message',[_vm._v(" "+_vm._s(_vm.$trans("create_payment_invoice_info"))+" ")])],1):(_vm.showFakturowniaWarning)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("invoice_warning_fakturownia_disabled"))+" ")])],1):(_vm.showCustomerDataInvoiceWarning)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("invoice_warning_client_data"))+" ")])],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.paymentMethods,"hide-details":"","label":_vm.$trans('payment_method'),"outlined":"","rules":[_vm.rules.required],"disabled":_vm.isLoading || _vm.isMakingPayment},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"loading":_vm.loadingSubscriptionPrice,"hide-details":"","type":"number","rules":[
                  _vm.rules.required,
                  _vm.rules.maxNumber,
                  _vm.rules.minNumber(_vm.amount, 0) ],"label":_vm.$trans('invoice_total'),"outlined":"","suffix":_vm.$config('currency'),"disabled":_vm.isLoading || _vm.isMakingPayment},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","rules":[_vm.rules.maxChars(255)],"label":_vm.$trans('payment_transaction_transaction_id'),"outlined":"","disabled":_vm.isLoading || _vm.isMakingPayment},model:{value:(_vm.transactionId),callback:function ($$v) {_vm.transactionId=$$v},expression:"transactionId"}})],1)],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }