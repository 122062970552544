<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="d-flex align-center justify-center justify-sm-start"
        >
          <v-select
            v-model="paymentsTypeRequest"
            style="max-width: 250px"
            outlined
            hide-details
            :label="$trans('payment_type_select_box')"
            :items="paymentTypes"
            @change="loadPaymentsDebounce"
          />
        </v-col>
        <v-col
          v-if="showCreatePaymentButton"
          cols="12"
          sm="6"
          class="d-flex align-center justify-center justify-sm-end"
        >
          <v-btn
            :disabled="createPaymentButtonDisabled"
            depressed
            outlined
            color="secondary"
            @click="createPayment"
          >
            <v-icon left>$plus-circle</v-icon>
            <span>{{ $trans("create_payment") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            class="user-payment-table form__container pt-6"
            :headers="headers"
            :items="payments"
            :options.sync="tableOptions"
            :server-items-length="total"
            :loading="isLoadingPayments"
            :no-results-text="$trans('nothing_found_here')"
            :no-data-text="$trans('nothing_found_here')"
            :loading-text="$trans('loading')"
            :footer-props="footerProps"
          >
            <template #[`item.paid_at`]="{ item }">
              {{ item.paid_at | dateTime($helpers.hourDayMonthAndYearDate) }}
            </template>
            <template #[`item.transaction_id`]="{ item }">
              {{ item.transaction_id }}
            </template>
            <template #[`item.price`]="{ item }">
              {{ item.price | money }}
              <template v-if="item.promo_code"
                ><br />{{ item.promo_code }}</template
              >
              <br />
              {{ $trans(item.provider_tag) }}
            </template>
            <template #[`item.booking.service.name`]="{ item }">
              <span v-if="item.booking">
                {{ item.booking.service.name }}
                <span v-if="item.booking.service_type" class="font-weight-thin"
                  >({{ item.booking.service_type.name }})</span
                >
              </span>
            </template>
            <template #[`item.booking.start_date`]="{ item }">
              <span v-if="item.booking">
                {{
                  item.booking.starts_at
                    | dateTime($helpers.hourDayMonthAndYearDate)
                }}
              </span>
            </template>
            <template #[`item.subscription_name`]="{ item }">
              <span
                v-if="
                  item.user_subscription && item.user_subscription.subscription
                "
                >({{ $trans("id") }}:
                {{ item.user_subscription.subscription.id }})
                {{ item.user_subscription.subscription.name }}</span
              >
            </template>
            <template #[`item.delete_booking_payment_transaction`]="{ item }">
              <v-btn
                icon
                fab
                text
                @click.native="
                  askForDeleteBookingPaymentTransaction(item.booking)
                "
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
            <template
              #[`item.delete_user_subscription_payment_transaction`]="{ item }"
            >
              <v-btn
                icon
                fab
                text
                @click.native="
                  askForDeleteUserSubscriptionPaymentTransaction(item)
                "
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
            <template
              #[`item.delete_user_simple_store_product_payment_transaction`]="{
                item,
              }"
            >
              <v-btn
                icon
                fab
                text
                @click.native="
                  askForDeleteUserSimpleStorePaymentTransaction(item)
                "
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <create-user-subscription-payment-transaction-dialog
      @close="loadPayments"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import dataTableFooter from "@/calendesk/prototypes/dataTableFooter";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import CreateUserSubscriptionPaymentTransactionDialog from "@/components/Forms/CreateUserSubscriptionPaymentTransactionDialog";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "UserPayments",
  components: {
    CreateUserSubscriptionPaymentTransactionDialog,
  },
  mixins: [sharedActions],
  props: {
    forceMode: {
      type: Number,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
    userSubscription: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      paymentsTypeRequest: this.$helpers.userPaymentTypeMode.BOOKINGS,
      userPaymentTypeModes: this.$helpers.userPaymentTypeMode,
      payments: [],
      isLoadingPayments: false,
      total: null,
      from: 1,
      to: 1,
      tableOptions: {
        itemsPerPage: 25,
        sortBy: ["start_date"],
        sortDesc: [true],
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
      shouldRefreshUserPayments: "user/shouldRefreshUserPayments",
    }),
    paymentTypes() {
      const data = [];

      if (
        !this.forceMode ||
        this.forceMode === this.userPaymentTypeModes.BOOKINGS ||
        this.forceMode === this.userPaymentTypeModes.SIMPLE_STORE_PRODUCTS
      ) {
        data.push({
          text: this.$trans("booking_payments"),
          value: this.userPaymentTypeModes.BOOKINGS,
        });

        data.push({
          text: this.$trans("simple_store"),
          value: this.userPaymentTypeModes.SIMPLE_STORE_PRODUCTS,
        });
      }

      if (
        !this.forceMode ||
        this.forceMode === this.userPaymentTypeModes.USER_SUBSCRIPTIONS
      ) {
        data.push({
          text: this.$trans("subscriptions"),
          value: this.userPaymentTypeModes.USER_SUBSCRIPTIONS,
        });
      }

      return data;
    },
    showCreatePaymentButton() {
      return (
        (this.userSubscription &&
          this.paymentsTypeRequest ===
            this.userPaymentTypeModes.USER_SUBSCRIPTIONS) ||
        (this.booking &&
          this.paymentsTypeRequest === this.userPaymentTypeModes.BOOKINGS)
      );
    },
    createPaymentButtonDisabled() {
      return (
        this.isLoadingPayments ||
        (this.paymentsTypeRequest === this.userPaymentTypeModes.BOOKINGS &&
          this.payments &&
          this.payments.length > 0)
      );
    },
    footerProps() {
      return dataTableFooter;
    },
    request() {
      if (
        this.paymentsTypeRequest === this.$helpers.userPaymentTypeMode.BOOKINGS
      ) {
        return "fetchPaymentsBookingsUser";
      }
      if (
        this.paymentsTypeRequest ===
        this.$helpers.userPaymentTypeMode.USER_SUBSCRIPTIONS
      ) {
        return "fetchAllSubscriptionTransactions";
      }
      if (
        this.paymentsTypeRequest ===
        this.$helpers.userPaymentTypeMode.SIMPLE_STORE_PRODUCTS
      ) {
        return "fetchAllProductTransactions";
      }

      return "fetchPaymentsBookingsUser";
    },
    headers() {
      if (
        this.paymentsTypeRequest === this.$helpers.userPaymentTypeMode.BOOKINGS
      ) {
        return [
          {
            text: this.$trans("booking_id"),
            value: "booking.id",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("date"),
            value: "paid_at",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("transaction_id"),
            sortable: false,
            value: "transaction_id",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("price"),
            sortable: false,
            value: "price",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("service"),
            sortable: false,
            value: "booking.service.name",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("service_date"),
            value: "booking.start_date",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("invoice_number"),
            value: "invoice_number",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("delete"),
            value: "delete_booking_payment_transaction",
            sortable: false,
            class: "header-style",
            align: "center",
          },
        ];
      }
      if (
        this.paymentsTypeRequest ===
        this.$helpers.userPaymentTypeMode.USER_SUBSCRIPTIONS
      ) {
        return [
          {
            text: this.$trans("user_subscription_id"),
            value: "user_subscription_id",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("date"),
            value: "paid_at",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("transaction_id"),
            sortable: false,
            value: "transaction_id",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("price"),
            sortable: false,
            value: "price",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("invoice_number"),
            value: "invoice_number",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("subscription_name"),
            value: "subscription_name",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("delete"),
            value: "delete_user_subscription_payment_transaction",
            sortable: false,
            class: "header-style",
            align: "center",
          },
        ];
      }
      if (
        this.paymentsTypeRequest ===
        this.$helpers.userPaymentTypeMode.SIMPLE_STORE_PRODUCTS
      ) {
        return [
          {
            text: this.$trans("date"),
            value: "paid_at",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("transaction_id"),
            sortable: false,
            value: "transaction_id",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("price"),
            sortable: false,
            value: "price",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("invoice_number"),
            value: "invoice_number",
            sortable: false,
            class: "header-style",
          },
          {
            text: this.$trans("description"),
            sortable: false,
            value: "product.name",
            align: "start",
            class: "header-style",
          },
          {
            text: this.$trans("delete"),
            value: "delete_user_simple_store_product_payment_transaction",
            sortable: false,
            class: "header-style",
            align: "center",
          },
        ];
      }

      return [];
    },
  },
  watch: {
    tableOptions() {
      this.loadPaymentsDebounce();
    },
    "getDialog.open": function () {
      if (this.getDialog.data && this.getDialog.data.reload === true) {
        this.loadPaymentsDebounce();
      }
    },
    shouldRefreshUserPayments() {
      this.loadPaymentsDebounce();
    },
  },
  created() {
    if (this.forceMode) {
      this.paymentsTypeRequest = this.forceMode;
    }

    this.isLoadingPayments = true;
    this.loadPaymentsDebounce();
  },
  methods: {
    ...mapActions({
      fetchAllSubscriptionTransactions:
        "subscriptions/fetchAllSubscriptionTransactions",
      fetchPaymentsBookingsUser: "payments/fetchPaymentsBookingsUser",
      fetchAllProductTransactions: "simpleStore/fetchAllProductTransactions",
      deleteBookingPaymentTransaction:
        "booking/deleteBookingPaymentTransaction",
      refreshBookings: "booking/refreshBookings",
      refreshProductList: "simpleStore/refreshProductList",
      deleteUserSubscriptionPaymentTransaction:
        "subscriptions/deleteUserSubscriptionPaymentTransaction",
      setCreateBookingTransactionDialog:
        "booking/setCreateBookingTransactionDialog",
      setCreateUserSubscriptionTransactionDialog:
        "subscriptions/setCreateUserSubscriptionTransactionDialog",
      deleteSimpleStoreProductPaymentTransaction:
        "simpleStore/deletePaymentTransaction",
    }),
    createPayment() {
      if (
        this.paymentsTypeRequest ===
          this.$helpers.userPaymentTypeMode.BOOKINGS &&
        this.booking
      ) {
        const price = this.booking.service_type
          ? this.booking.service_type.price
          : this.booking.service.price;
        this.setCreateBookingTransactionDialog({
          booking: this.booking,
          amount: price,
        });
      } else if (
        this.paymentsTypeRequest ===
          this.$helpers.userPaymentTypeMode.USER_SUBSCRIPTIONS &&
        this.userSubscription
      ) {
        this.setCreateUserSubscriptionTransactionDialog({
          userSubscription: this.userSubscription,
        });
      }
    },
    askForDeleteBookingPaymentTransaction(item) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.isLoadingPayments = true;
          this.closeConfirmDialog();
          this.deleteBookingPaymentTransaction(item.id).finally(() => {
            this.loadPayments();
            this.refreshBookings();
          });
        },
      });
    },
    askForDeleteUserSubscriptionPaymentTransaction(item) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.isLoadingPayments = true;
          this.closeConfirmDialog();
          this.deleteUserSubscriptionPaymentTransaction({
            user_subscription_id: item.user_subscription_id,
            user_subscription_payment_transaction_id: item.id,
          }).finally(() => {
            this.loadPayments();
            this.refreshBookings();
          });
        },
      });
    },
    askForDeleteUserSimpleStorePaymentTransaction(item) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.isLoadingPayments = true;
          this.closeConfirmDialog();
          this.deleteSimpleStoreProductPaymentTransaction(item.id).finally(
            () => {
              this.loadPayments();
              this.refreshBookings();
              this.refreshProductList();
            }
          );
        },
      });
    },
    loadPaymentsDebounce: debounce(function () {
      this.loadPayments();
    }, 400),
    loadPayments() {
      this.isLoadingPayments = true;
      this.payments = [];

      const data = {
        user_id: this.selectedUser.id,
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        ascending: this.tableOptions.sortDesc[0] ? 0 : 1,
      };

      if (
        this.booking &&
        (this.paymentsTypeRequest ===
          this.$helpers.userPaymentTypeMode.BOOKINGS ||
          this.paymentsTypeRequest ===
            this.$helpers.userPaymentTypeMode.SIMPLE_STORE_PRODUCTS)
      ) {
        data.booking_id = this.booking.id;
      } else if (
        this.paymentsTypeRequest ===
          this.$helpers.userPaymentTypeMode.USER_SUBSCRIPTIONS &&
        this.userSubscription
      ) {
        data.user_subscription_ids = this.userSubscription.id;
      }

      this[this.request](data)
        .then((response) => {
          this.payments = response.data;
          this.total = response.total;
          this.from = response.from;
          this.to = response.to;
        })
        .finally(() => {
          this.isLoadingPayments = false;
        });
    },
  },
};
</script>
<style lang="scss">
.user-payment-table {
  .header-style {
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #263238;
      opacity: 0.6;
    }
  }

  .v-data-footer {
    .v-data-footer__pagination {
      margin: 0;
    }

    .v-data-footer__select .v-select {
      margin: 13px 0 13px 10px;
    }
  }
}
</style>
