var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"outlined":"","hide-details":"","label":_vm.$trans('payment_type_select_box'),"items":_vm.paymentTypes},on:{"change":_vm.loadPaymentsDebounce},model:{value:(_vm.paymentsTypeRequest),callback:function ($$v) {_vm.paymentsTypeRequest=$$v},expression:"paymentsTypeRequest"}})],1),(_vm.showCreatePaymentButton)?_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.createPaymentButtonDisabled,"depressed":"","outlined":"","color":"secondary"},on:{"click":_vm.createPayment}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$plus-circle")]),_c('span',[_vm._v(_vm._s(_vm.$trans("create_payment")))])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"user-payment-table form__container pt-6",attrs:{"headers":_vm.headers,"items":_vm.payments,"options":_vm.tableOptions,"server-items-length":_vm.total,"loading":_vm.isLoadingPayments,"no-results-text":_vm.$trans('nothing_found_here'),"no-data-text":_vm.$trans('nothing_found_here'),"loading-text":_vm.$trans('loading'),"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.paid_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.paid_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")]}},{key:"item.transaction_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transaction_id)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.price))+" "),(item.promo_code)?[_c('br'),_vm._v(_vm._s(item.promo_code))]:_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.$trans(item.provider_tag))+" ")]}},{key:"item.booking.service.name",fn:function(ref){
var item = ref.item;
return [(item.booking)?_c('span',[_vm._v(" "+_vm._s(item.booking.service.name)+" "),(item.booking.service_type)?_c('span',{staticClass:"font-weight-thin"},[_vm._v("("+_vm._s(item.booking.service_type.name)+")")]):_vm._e()]):_vm._e()]}},{key:"item.booking.start_date",fn:function(ref){
var item = ref.item;
return [(item.booking)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.booking.starts_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")]):_vm._e()]}},{key:"item.subscription_name",fn:function(ref){
var item = ref.item;
return [(
                item.user_subscription && item.user_subscription.subscription
              )?_c('span',[_vm._v("("+_vm._s(_vm.$trans("id"))+": "+_vm._s(item.user_subscription.subscription.id)+") "+_vm._s(item.user_subscription.subscription.name))]):_vm._e()]}},{key:"item.delete_booking_payment_transaction",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","text":""},nativeOn:{"click":function($event){return _vm.askForDeleteBookingPaymentTransaction(item.booking)}}},[_c('v-icon',[_vm._v("$trash-default")])],1)]}},{key:"item.delete_user_subscription_payment_transaction",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","text":""},nativeOn:{"click":function($event){return _vm.askForDeleteUserSubscriptionPaymentTransaction(item)}}},[_c('v-icon',[_vm._v("$trash-default")])],1)]}},{key:"item.delete_user_simple_store_product_payment_transaction",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","text":""},nativeOn:{"click":function($event){return _vm.askForDeleteUserSimpleStorePaymentTransaction(item)}}},[_c('v-icon',[_vm._v("$trash-default")])],1)]}}],null,true)})],1)],1)],1),_c('create-user-subscription-payment-transaction-dialog',{on:{"close":_vm.loadPayments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }